<script lang="ts" setup>
import { UIButton, UIIcon } from '@groover-dev/groover-ui'
import { useClipboard } from '@vueuse/core'

import VLoader from '~/components/shared/loader.vue'
import Modal from '~/components/shared/modal.vue'
import ModalFooter from '~/components/shared/modal/footer.vue'

import {
  isAudiomackTrackUrl,
  isSpotifyUrl,
  isYoutubeUrl,
} from '~/utils/url-validation'

type Props = {
  trackLink: string
  loading: boolean
}

type Emits = {
  share: []
}

type Slots = {
  canvas(): any
  colorPicker(): any
  title(): any
}

const props = withDefaults(defineProps<Props>(), {
  loading: () => false,
})
defineSlots<Slots>()
defineEmits<Emits>()

const display = defineModel<boolean>({
  default: () => false,
})
const { copy: copyToClipboard } = useClipboard()

const clipboardLinkType = isSpotifyUrl(props.trackLink)
  ? 'spotify'
  : isYoutubeUrl(props.trackLink)
    ? 'youtube'
    : isSoundCloudUrl(props.trackLink)
      ? 'soundcloud'
      : isAudiomackTrackUrl(props.trackLink)
        ? 'audiomack'
        : (undefined as never)
const clipboardLinkState = ref<'uncopied' | 'copied'>('uncopied')
const isClipboardCopyAllowed = computed(
  () => clipboardLinkState.value === 'uncopied',
)

function handleClipboardLinkClick() {
  copyToClipboard(props.trackLink)
  clipboardLinkState.value = 'copied'
  setTimeout(() => (clipboardLinkState.value = 'uncopied'), 10000)
}
</script>

<template>
  <Modal v-model="display" @close="display = false">
    <template #title><slot name="title" /></template>
    <template #default>
      <Transition name="fade">
        <div
          v-if="!loading"
          key="loaded"
          class="tw-grid tw-gap-3xl tw-pb-8xl sm:tw-pb-0"
        >
          <div
            class="tw-relative tw-mx-auto tw-aspect-[9/16] tw-w-full tw-max-w-64 tw-overflow-hidden"
          >
            <slot name="canvas" />
          </div>
          <div class="tw-grid tw-gap-xl">
            <span class="tw-text-base tw-font-medium">{{
              $t(
                'components.submission.ShareOnInstagramModal.selectBackgroundColor',
              )
            }}</span>
            <slot name="colorPicker" />
          </div>
          <hr class="tw-h-px tw-w-full tw-border-t tw-border-discrete" />
          <UIButton
            v-if="isClipboardCopyAllowed"
            class="tw-border-2 tw-border-black"
            type="button"
            color="neutral"
            variant="outlined"
            size="large"
            :icon-left-name="`simple-icons:${clipboardLinkType}`"
            :text="
              $t(
                `components.submission.ShareOnInstagramModal.clipboardLink.uncopied`,
                {
                  type: $t(
                    `components.submission.ShareOnInstagramModal.clipboardLink.type.${clipboardLinkType}`,
                  ),
                },
              )
            "
            @click="handleClipboardLinkClick"
          />
          <div
            v-else
            class="tw-flex tw-h-5xl tw-w-full tw-items-center tw-justify-center tw-gap-1"
          >
            <UIIcon :name="`simple-icons:${clipboardLinkType}`" size="lg" />
            <span class="tw-text-base">
              {{
                $t(
                  `components.submission.ShareOnInstagramModal.clipboardLink.copied`,
                  {
                    type: $t(
                      `components.submission.ShareOnInstagramModal.clipboardLink.type.${clipboardLinkType}`,
                    ),
                  },
                )
              }}
            </span>
            <UIIcon
              name="material-symbols:check"
              class="tw-text-icon-success"
            />
          </div>
          <ModalFooter @cancel="display = false" @next="$emit('share')">
            <template #next>{{
              $t(
                'components.band.dashboard.new.submissionDetails.influencerSharedLinkDisplay.share',
              )
            }}</template>
          </ModalFooter>
        </div>
        <div
          v-else
          key="loading"
          class="tw-relative tw-flex tw-h-10xl tw-w-full tw-items-center tw-justify-center tw-overflow-hidden"
        >
          <VLoader />
        </div>
      </Transition>
    </template>
    <!-- Use this when migrating to UIModal -->
    <!-- <template #cta>
      <div
        class="tw-grid tw-grid-cols-2 tw-items-center tw-justify-center tw-gap-sm"
      >
        <UIButton
          size="large"
          variant="transparent"
          :text="$t('common.cancel')"
          @click="display = false"
        />
        <UIButton
          size="large"
          :text="
            $t(
              'components.band.dashboard.new.submissionDetails.influencerSharedLinkDisplay.share',
            )
          "
          @click="$emit('share')"
        />
      </div>
    </template> -->
  </Modal>
</template>
