export function useSharePictureOnInstagram() {
  const { isAndroid } = useDevice()

  function downloadImage(encodedImage: string) {
    const link = document.createElement('a')
    link.href = encodedImage
    link.download = `instagram-goover-share-${Date.now()}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    sharePictureOnInstagram(picture: string | null | undefined) {
      if (!picture) return Promise.resolve()
      return new Promise<void>((resolve) => {
        downloadImage(picture)
        setTimeout(() => {
          // On android try to open instagram trought the URL schema, but don't on IOS
          // IOS file download handler is complete enough to handle the sharing part itself.
          if (isAndroid) open('instagram://story-camera')
          resolve()
        }, 1500)
      })
    },
  } as const
}
